import React from "react";
import { useInvocationCheck } from "../../hooks/useInvocationCheck";
import { useBodyScrollLock } from "../../hooks/useBodyScrollLock";

import OrientationLock from "../common/layout/screenOrientation/OrientationLock";
import TutorialComponent from "./TutorialComponent";

type Props = {};

function Tutorial(props: Props) {
  useInvocationCheck();
  useBodyScrollLock();

  return (
    <OrientationLock>
      <TutorialComponent />
    </OrientationLock>
  );
}

export default Tutorial;
