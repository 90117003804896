import { useInvocationCheck } from "../../hooks/useInvocationCheck";
import { useRouter } from "../../hooks/useRouter";
import { useBodyScrollLock } from "../../hooks/useBodyScrollLock";
import React, { useEffect, useRef } from "react";
import IconButton from "../common/ui/IconButton";
import { FaqData } from "../faq/FaqData";
import { Helmet } from "react-helmet-async";
import ProgressBar from "./ProgressBar";
import NetworkIndicator from "./NetworkIndicator";
import SwipeField from "./SwipeField";
import SwipeMessage from "./SwipeMessage";
import { SwipeData } from "./SwipeData";
import OrientationLock from "../common/layout/screenOrientation/OrientationLock";
import Sound from "../common/sound/Sound";
import click from "../../assets/sounds/click.mp3";

type Props = {};

function Swipe(props: Props) {
  const router = useRouter();

  const scaleFactor = SwipeData.useState((s) => s.scaleFactor);

  const clickSound = new Sound(click);

  useInvocationCheck();
  useBodyScrollLock();

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
    } else {
      // do componentDidUpdate logic
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  });

  return (
    <OrientationLock>
      <div>
        <Helmet>
          <meta
            name="viewport"
            content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />

          <meta name="format-detection" content="telephone=no" />
          <meta name="msapplication-tap-highlight" content="no" />

          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-title" content="eDLC" />
          <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        </Helmet>
        <div className="flex flex-col w-screen h-screen bg-white">
          <div>
            <SwipeMessage />
            <SwipeField scaleFactor={scaleFactor} />
            <ProgressBar />
            <NetworkIndicator />
          </div>
          <div className="absolute top-0 flex items-center justify-center w-screen h-16 text-center">
            <div className="absolute left-0">
              <IconButton
                icon="x"
                onClick={() => {
                  clickSound.play();
                  router.push("/start");
                }}
              />
            </div>
            <div className="absolute right-0">
              <IconButton
                icon="help-circle"
                onClick={() => {
                  clickSound.play();
                  FaqData.update((s) => {
                    s.origin = "/swipe";
                  });
                  router.push("/faq");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </OrientationLock>
  );
}

export default Swipe;
