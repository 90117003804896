import React from "react";
import HeaderBar from "../common/components/HeaderBar";

import prismaid from "../../assets/img/logos/prismaid.svg";

function Header() {
  return (
    <div className="absolute top-0 h-screen overflow-hidden">
      <HeaderBar>
        <div className="flex items-center justify-between w-full mx-4">
          <img src={prismaid} alt="Logo PrismaID" className="h-8" />
          <h1 className="text-lg italic font-medium uppercase text-prismade-blue">Document&nbsp;Verifier</h1>
        </div>
      </HeaderBar>
    </div>
  );
}

export default Header;
