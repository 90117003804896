import { ReactNode } from "react";
import SwipeableViews from "react-swipeable-views";
import anime from "animejs";
import { TutorialData } from "./TutorialData";

type Props = {
  children: ReactNode;
};

function TutorialContentContainer(props: Props) {
  const currentSlide = TutorialData.useState((s) => s.currentSlide);

  // animates switching on swiping
  // buttonClickAnimation is implemented in Tutorial
  const onChangeIndex = (index: number) => {
    anime({
      targets: ".tutorialImage",
      opacity: 0,
      duration: 200,
      complete: () => {
        TutorialData.update((s) => {
          s.currentSlide = index;
        });
        anime({ targets: ".tutorialImage", opacity: 1, duration: 500 });
      },
    });
  };

  return (
    <div>
      <SwipeableViews
        enableMouseEvents
        onChangeIndex={onChangeIndex}
        className={"px-4 h-full"}
        index={currentSlide}
      >
        {props.children}
      </SwipeableViews>
    </div>
  );
}

export default TutorialContentContainer;
