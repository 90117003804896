import React from "react";
import { useTranslation } from "react-i18next";
import TutorialContentContainer from "./TutorialContentContainer";
import TutorialSlide from "./TutorialSlide";

function TutorialContent() {
  const { t } = useTranslation();

  return (
    <TutorialContentContainer>
      <TutorialSlide text={t("tutorial:slide.1")}></TutorialSlide>
      <TutorialSlide text={t("tutorial:slide.2")}></TutorialSlide>
      <TutorialSlide text={t("tutorial:slide.3")}></TutorialSlide>
      {/* <TutorialSlide text={t("tutorial:slide.4")}></TutorialSlide> */}
    </TutorialContentContainer>
  );
}

export default TutorialContent;
