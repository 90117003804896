import React from "react";
import { useInvocationCheck } from "../../hooks/useInvocationCheck";
import OrientationLock from "../common/layout/screenOrientation/OrientationLock";
import TouchComponent from "./TouchComponent";

type Props = {};

function TouchSensitivity(props: Props) {
  useInvocationCheck();

  return (
    <OrientationLock>
      <TouchComponent />
    </OrientationLock>
  );
}

export default TouchSensitivity;
