import anime from "animejs";
import { useRouter } from "../../hooks/useRouter";
import { useTranslation } from "react-i18next";
import { useInvocationCheck } from "../../hooks/useInvocationCheck";
import { useDataReset } from "../../hooks/useDataReset";
import OrientationLock from "../common/layout/screenOrientation/OrientationLock";
import MessageTitle from "../common/components/MessageTitle";
import IconButton from "../common/ui/IconButton";
import Button from "../common/ui/Button";
import Sound from "../common/sound/Sound";

import click from "../../assets/sounds/click.mp3";
import { useEffect } from "react";
import card from "./img/cm_front.jpg";
import check from "./img/verified_checkmark@2x.png";

type Props = {};

function Verified(props: Props) {
  const router = useRouter();
  const { t } = useTranslation();

  const clickSound = new Sound(click);

  useInvocationCheck();
  useDataReset();

  useEffect(() => {
    anime.set(["#check_container"], {
      scale: 0.5,
      opacity: 0.0,
    });
    anime.set(["#card"], {
      opacity: 0,
    });
    setTimeout(() => {
      anime({
        targets: ["#check_container"],
        opacity: 1.0,
        scale: 1.1,
        duration: 1000,
        delay: 1000,
        easing: "easeInOutQuad",
        complete: () => {
          anime({
            targets: ["#check_container"],
            scale: 1,
            duration: 500,
            easing: "easeInOutQuad",
          });
        },
      });

     
      anime({
        targets: ["#card"],
        scale: 1.2,
        opacity: 1,
        duration: 500,
        delay: 500,
        easing: "easeInOutQuad",
        complete: () => {
          anime({
            targets: ["#card"],
            scale: 1.0,
            duration: 500,
            easing: "easeInOutQuad",
          });
        },
      });
    }, 500);
  }, []);

  return (
    <OrientationLock>
      <div
        className="absolute top-0 flex flex-col items-center justify-between w-screen h-screen p-8 text-center bg-center bg-no-repeat bg-cover"
      >
        <div className="absolute z-10 flex items-center justify-center w-screen text-center">
          <div className="absolute left-0">
            <IconButton
              icon="arrow-left"
              onClick={() => {
                clickSound.play();
                router.push("/start");
              }}
            />
          </div>
          <MessageTitle>{t("success:title")}</MessageTitle>
        </div>
        <div className="absolute top-16 left-0 z-0 flex justify-center w-screen mt-2">
            <img src={card} id="card" alt="" style={{width: "65%"}} />
        </div>
        <div className="absolute bottom-0 z-10 w-screen justify-center p-8">
        <div id="check_container" className="mt-2">
            <img src={check} alt="checkmark" className="h-24 mx-auto mt-2" />
            <p className="text-2xl font-semibold text-center text-green-600 ">{t("success:cardName")}</p>
        </div>
        <div className="w-full mt-4">
          <Button
            onClick={() => {
              window.open("https://www.racemates-nft.com/");
            }}
          >
            {t("success:button")}
          </Button>
        </div>
      </div>
      </div>
    </OrientationLock>
  );
}

export default Verified;
