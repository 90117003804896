import React, { useEffect, useRef, useState } from "react";
import anime from "animejs";
import { useRouter } from "../../hooks/useRouter";
import { useInvocationCheck } from "../../hooks/useInvocationCheck";
import { useBodyScrollLock } from "../../hooks/useBodyScrollLock";
import { useTranslation } from "react-i18next";
import { TutorialData } from "./TutorialData";
import Header from "./Header";
import Spinner from "../common/components/Spinner";
import IconButton from "../common/ui/IconButton";
import Button from "../common/ui/Button";
import TutorialImage from "./TutorialImage";
import Pagination from "../common/components/Pagination";
import TutorialContent from "./TutorialContent";
import Sound from "../common/sound/Sound";
import click from "../../assets/sounds/click.mp3";

import image1 from "./img/01_welcome.gif";
import image2 from "./img/02_placement.gif";
import image3 from "./img/03_swipe.gif";
import { AppData } from "../open/AppData";

type Props = {};

function TutorialComponent(props: Props) {
  const router = useRouter();
  const { t } = useTranslation();

  const [imageIsReady1, setImageIsReady1] = useState(false);
  const [imageIsReady2, setImageIsReady2] = useState(false);
  const [imageIsReady3, setImageIsReady3] = useState(false);

  const [buttonTitle, setButtonTitle] = useState("");
  const [onClick, setOnClick] = useState(() => {});

  const needsTouchSensitivity = AppData.useState((s) => s.needsTouchSensitivity);
  const currentSlide = TutorialData.useState((s) => s.currentSlide);

  const clickSound = new Sound(click);

  useInvocationCheck();
  useBodyScrollLock();

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      // trigger image downloads
      var allImages = [];

      const img_1 = new Image();
      img_1.src = image1;
      img_1.onload = () => {
        setImageIsReady1(true);
      };
      const img_2 = new Image();
      img_2.src = image2;
      img_2.onload = () => {
        setImageIsReady2(true);
      };
      const img_3 = new Image();
      img_3.src = image3;
      img_3.onload = () => {
        setImageIsReady3(true);
      };

      allImages.push(img_1);
      allImages.push(img_2);
      allImages.push(img_3);
    } else {
      // do componentDidUpdate logic
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  });

  useEffect(() => {
    const changeSlide = () => {
      const nextSlide = currentSlide + 1;

      clickSound.play();

      // animates switching on buttonClick
      // swipeAnimation is implemented in TutorialContentContainer
      anime({
        targets: ".tutorialImage",
        opacity: 0,
        duration: 200,
        complete: () => {
          TutorialData.update((s) => {
            s.currentSlide = nextSlide;
          });
          anime({ targets: ".tutorialImage", opacity: 1, duration: 500 });
        },
      });
    };

    const startButtonIsClicked = () => {
      clickSound.play();
      if (needsTouchSensitivity) {
        router.push("/touchSensitivity");
      } else {
        router.push("/swipe");
      }

      setTimeout(() => {
        TutorialData.update((s) => {
          s.currentSlide = 0;
        });
      }, 1000);
    };

    if (currentSlide === 2) {
      setButtonTitle("tutorial:startApplication");
      setOnClick(() => startButtonIsClicked);
    } else {
      setButtonTitle("tutorial:nextSlide");
      setOnClick(() => changeSlide);
    }
  }, [needsTouchSensitivity, currentSlide, router]);

  const onChangeIndex = (index: number) => {
    TutorialData.update((s) => {
      s.currentSlide = index;
    });
  };

  if (imageIsReady1 && imageIsReady2 && imageIsReady3) {
    return (
      <div className="w-screen h-screen bg-white">
        <TutorialImage />
        <Header />

        <div className="absolute bottom-0 flex items-end w-full h-screen">
          <div className="flex flex-col justify-end w-full h-full pb-10 mt-12">
            <TutorialContent />
            <div className="mt-1 mb-3">
              <Pagination dots={3} index={currentSlide} onChangeIndex={onChangeIndex} handleOnPaginationClick={() => {}} />
            </div>
            <div className="px-6">
              <Button onClick={onClick} className="w-full">
                {t(buttonTitle)}
              </Button>
            </div>
          </div>
        </div>

        <div className="absolute top-0 left-0 mt-12">
          {currentSlide === 0 ? (
            <IconButton
              icon="arrow-left"
              onClick={() => {
                clickSound.play();
                router.push("/start");
              }}
            />
          ) : (
            <IconButton
              icon="arrow-left"
              onClick={() => {
                clickSound.play();
                TutorialData.update((s) => {
                  s.currentSlide = currentSlide - 1;
                });
              }}
            />
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="w-screen h-screen bg-white">
        <Spinner />
        <Header />
      </div>
    );
  }
}

export default TutorialComponent;
